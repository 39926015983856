/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */

import baseApi from '@features/base/BaseSlice';
import f30 from './f30';
import {
  F30ApiProps,
  F30DocumentedWorkersApiProps,
  F30Params,
  F30WorkersApiProps,
  F30WorkersParams,
  UploadF30ApiProps,
  UploadF30Params,
} from './types';

// Add Tag to the list of tags to be invalidated when the list of attachments is updated
const f30Tag = baseApi.enhanceEndpoints({ addTagTypes: [f30.tagType] });
const f30Api = f30Tag.injectEndpoints({
  endpoints: (builder) => ({
    getf30DocumentsByMonth: builder.query<F30ApiProps, F30Params>(
      f30.getF30DocumentsByMonth(),
    ),
    getF30Workers: builder.query<F30WorkersApiProps, F30WorkersParams>(
      f30.getF30Workers(),
    ),
    getF30DocumentedWorkers: builder.query<F30DocumentedWorkersApiProps, F30WorkersParams>(
      f30.getF30DocumentedWorkersWithNoAccess(),
    ),
    uploadF30: builder.mutation<UploadF30ApiProps, UploadF30Params>(
      f30.uploadF30(),
    ),
    uploadF30Justification: builder.mutation<UploadF30ApiProps, UploadF30Params>(
      f30.uploadJustification(),
    ),
    deleteF30Justification: builder.mutation<UploadF30ApiProps, UploadF30Params>(
      f30.deleteJustification(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetf30DocumentsByMonthQuery,
  useGetF30WorkersQuery,
  useGetF30DocumentedWorkersQuery,
  useUploadF30Mutation,
  useUploadF30JustificationMutation,
  useDeleteF30JustificationMutation,
} = f30Api;
