/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import site from './site';
import { EmployersApiProps, FacilitiesApiProps, SiteApiProps } from './types';

const siteTag = baseApi.enhanceEndpoints({ addTagTypes: [site.tagType] });

const siteApi = siteTag.injectEndpoints({
  endpoints: (builder) => ({
    getEmployers: builder.query<EmployersApiProps, number>(site.getEmployers()),
    getFacilities: builder.query<FacilitiesApiProps, number>(site.getFacilities()),
    getSite: builder.query<SiteApiProps, number>(site.getEntity()),
  }),
  overrideExisting: false,
});

export const { useGetEmployersQuery, useGetFacilitiesQuery, useGetSiteQuery } = siteApi;
