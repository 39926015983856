/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import certificate from './certificate';
import { CertificateApiProps, CertificatesApiProps } from './types';

// Add Tag to the list of tags to be invalidated when the list of afps is updated
const certificateTag = baseApi.enhanceEndpoints({ addTagTypes: [certificate.tagType] });
const certificateApi = certificateTag.injectEndpoints({
  endpoints: (builder) => ({
    addCertificate: builder.mutation<CertificateApiProps, { id: number }>(
      certificate.addEntity('jobs'),
    ),
    getCertificates: builder.query<CertificatesApiProps, number>(
      certificate.getEntitiesWithId('jobs'),
    ),
  }),
  overrideExisting: false,
});

export const {
  useAddCertificateMutation,
  useGetCertificatesQuery,
} = certificateApi;
